import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import IncreaseIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import SplitRefundDialogContent from "./SplitRefundDialogContent";
import SnackBar from "../../HelperComponents/SnackBar/SnackBar";
import DefaultButton from "../../Buttons/DefaultButton/DefaultButton";
import AddIcon from '../../../assets/image/add.png';
import CloseDialogIcon from '../../../assets/image/Mask.png';
import IconAlert from '../../../assets/image/alert_amount.png';
import Iconfile from '../../../assets/image/file.png';
import IconAttach from '../../../assets/image/attach.png';
import IconDeleteFile from '../../../assets/image/delete_file.png';
import { isArray } from "../../../helpers/functions";
import { getOrderByNumber } from "../../../actions/ordersActions";
import { postCreateRequest } from "../../../actions/refundRequestsActions";
import './RefundRequestsHeaderButtonsPanel.scss'

const classes = {
    root: 'default_dialog_root transactions-dialog',
    paper:'paper_custom_dialog'
};

class RefundRequestsHeaderButtonsPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: { amount: 1, price: 0, id: 0, lot: 0, name: '-', producer: '-', article_number: '-' },
            comment: '',
            files: [],
            initSearch: false,
            hasResults: false,
            loadingBtn: false,
            open: false,
            splitDialogOpen: false,
            results: {data: [], query: ''},
            requestAmount: 1,
            requestSum: 0,
            searchValue: '',
            showSnackBar: false,
            snackBarAttr: {
                isVisible: false,
                message: '',
                type: '',
            }
        };
    }
    
    get disabledBtn() {
        const { activeItem, comment } = this.state;
        const isDisabled = !comment || !activeItem.id

        return isDisabled
    }

    toggleDialog = () => {
        this.setState(({open}) => {
            if (open) {
                return {
                    open: !open,
                    loadingBtn: false,
                    searchValue: '',
                    initSearch: false,
                    hasResults: false,
                    splitDialogOpen: false,
                    files: [],
                    comment: '',
                    messageSnackBar: '',
                    activeItem: { amount: 1, price: 0, id: 0, lot: 0, name: '-', producer: '-', article_number: '-' },
                    results: {data: [], query: ''},
                    requestAmount: 1,
                    requestSum: 0,
                }
            }

            return {
                open: !open
            }
        });
    };

    toggleSplitDialog = () => {
        this.setState(({splitDialogOpen}) => {
            return {
                splitDialogOpen: !splitDialogOpen
            }
        })
    }

    onSplitError = (error) => {
        this.setState({
            snackBarAttr: {
                isVisible: true,
                message: error,
                type: 'error'
            }
        })
    }

    onSplitSuccess = () => {
        this.setState({
            snackBarAttr: {
                isVisible: true,
                message: 'Заявка успешно разделена',
                type: 'success'
            }
        })
        this.toggleSplitDialog();
        this.props.refetch();
    }

    onChangeInput = (e) => {
        const value = e.target.value.replace(/\D/g,'');
        this.setState({ searchValue: value });
    };

    getMyOrdersByNumber = (value) => {
        const { getOrderByNumber } = this.props;
        getOrderByNumber(value).then((res) => {
            if (res.payload.data) {
                this.setState({
                    initSearch: true,
                    hasResults: true,
                    results: {
                        data: res.payload.data.results,
                        query: value
                    }
                });
            } else {
                this.setState({
                    hasResults: false,
                    results: {
                        data: [],
                        query: value
                    }
                })
            }

        })
    };

    handleActiveItem(index) {
        const array = [...this.state.results.data]; // make a separate copy of the array
        const { requestAmount } = this.state;
        const activeItem = array[index];
        this.setState({
            searchValue: activeItem.id,
            activeItem: activeItem,
            results: {data: [], query: ''},
            initSearch: false,
            value: `${activeItem.id}`,
            requestAmount: 1,
            requestSum: parseFloat(activeItem.price * requestAmount)
        },);
    }

    handleOnKey = (e) => {
        const { results, initSearch, searchValue } = this.state;
        if (searchValue.length >= 3 && results.query !== searchValue && searchValue.indexOf(" ") === -1) {
            this.getMyOrdersByNumber(searchValue);
        } else if (searchValue.length >= 3 && !initSearch) {
            this.setState({initSearch: true});
        } else if(searchValue.length < 3 && searchValue !== "" && e.keyCode === 13){
            this.getMyOrdersByNumber(searchValue);
        } else if (searchValue === "" || isArray(results.data)) {
            if(e.keyCode === 13){
                return false
            }
            this.setState({
                initSearch: false,
                requestAmount: 1,
                requestSum: 0,
                activeItem: { amount: 1, price: 0, id: 0, lot: 0, name: '-', producer: '-', article_number: '-' },
            });
        }
    };

    decreaseAmount = () => {
        const { requestAmount, activeItem } = this.state;
        let newValue = requestAmount - 1;
        if (newValue >= 1) {
            this.setState({
                requestAmount: newValue,
                addDisabled: false,
                requestSum: parseFloat(activeItem.price * newValue)
            });
        }
    };
    increaseAmount = () => {
        const { requestAmount, activeItem } = this.state;
        let newValue = parseInt(requestAmount + 1);
        this.setState({ requestAmount: newValue, requestSum: parseFloat(activeItem.price * newValue) });
    };

    onClickAttach = (e) => {
        e.preventDefault()
        this.upload.click()
    };

    onChangeText(e) {
        let comment = e.target.value;
        this.setState({comment: comment});
        if (comment.length >= 1 && this.state.activeItem['id'] !== 0) {
            this.setState({submitDisable: false})
        } else {
            this.setState({submitDisable: true})

        }
    }

    onChangeFile(e) {
        this.setState({files: this.state.files.concat(e.target.files[0])})
        e.target.value = ''
    }

    removeFile(index) {
        let array = [...this.state.files]; // make a separate copy of the array
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({files: array});
        }
    }

    toggleSnack = () => {
        this.setState({
            snackBarAttr: {
                isVisible: false,
                message: '',
                type: '',
            }
        });
    };

    createRefundRequestHandler = () => {
        this.setState({ loadingBtn: true })
        const { postCreateRequest } = this.props;
        const {files, activeItem, requestAmount, requestSum, comment} = this.state;
        const isCreatingRefundRequestsuccessfully = true;
        const formData = new FormData();
        files.map(file => {
            formData.append('files', file, file.name);
        });
        formData.append('company', activeItem.company);
        formData.append('price', requestSum);
        formData.append('ordernum', activeItem.ordernum);
        formData.append('order', activeItem.id);
        formData.append('comment', comment);
        formData.append('amount', requestAmount);
        postCreateRequest(formData).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                this.setState({ 
                    messageSnackBar: 'Заявка на возврат успешно создана', 
                    loadingBtn: false, 
                    snackBarAttr: {
                        isVisible: true,
                        message: 'Заявка на возврат успешно создана',
                        type: 'success'
                    } 
                });
                this.toggleDialog();
                this.props.createRefundRequest(isCreatingRefundRequestsuccessfully);
            } else {
                this.setState({ 
                    loadingBtn: false, 
                    snackBarAttr: {
                        isVisible: true,
                        message: res.error.response.data.error ? res.error.response.data.error[0] : 'При создания заявки на возврат произошла ошибка. Повторите попытку позже',
                        type: 'error'
                    }
                });
                this.props.createRefundRequest(!isCreatingRefundRequestsuccessfully);
            }
        })
    }

    render() {
        const { activeItem, files, initSearch, loadingBtn, open, results, requestAmount, requestSum, snackBarAttr, splitDialogOpen } = this.state;
        const { selectedRows } = this.props;

        return (
            <TransitionedBlock>
                <div className="add_button">
                    { this.props.selectedRowsLength === 0 &&
                        <Button
                            classes={{
                                root: 'add_btn'
                            }}
                            onClick={this.toggleDialog}
                        >
                            Добавить
                            <img src={ AddIcon } alt="edit icon"/>
                        </Button>
                    }
                    { this.props.selectedRowsLength === 1 &&
                        <Button
                            classes={{
                                root: 'add_btn'
                            }}
                            onClick={this.toggleSplitDialog}
                        >
                            Разделить
                            <img src={ AddIcon } alt="edit icon"/>
                        </Button>
                    }
                    { this.props.selectedRowsLength > 1 &&
                        <Button
                            classes={{
                                root: 'add_btn'
                            }}
                            onClick={() => this.props.createJoinRequest()}
                            disabled={this.props.actionLoading}
                        >
                            { this.props.actionLoading ? 'Загрузка...' : 'Объединить'}
                            <img src={ AddIcon } style={{ opacity: this.props.actionLoading ? 0.5 : 1 }} alt="edit icon"/>
                        </Button>
                    }
                </div>
                <DialogComponent
                    open={splitDialogOpen}
                    onClose={this.toggleSplitDialog}
                    classes={classes}
                    
                >
                    <SplitRefundDialogContent
                        onClose={this.toggleSplitDialog}
                        selectedRow={Object.values(selectedRows).find(item => item.checked)}
                        splitRefundRequest={this.props.splitRefundRequest}
                        onError={this.onSplitError}
                        onSuccess={this.onSplitSuccess}
                    />
                </DialogComponent>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                    classes={classes}
                >
                    <div className="title-dialog">
                        Создать заявку на возврат
                    </div>
                    <div className="dialog-form-wrapper">
                        <div className="requests_add">
                            <div className="requests_add_search">
                                <span>Номер заказа</span>
                                <input 
                                    type="text"
                                    className='requests_add_search-input'
                                    placeholder="Введите номер заказа"
                                    value={this.searchValue}
                                    onChange={(e) => this.onChangeInput(e)}
                                    onKeyUp={(e) => this.handleOnKey(e)}
                                    autoComplete="off"
                                />
                                {initSearch ?
                                    <div className="search_products_list">
                                        {isArray(results.data) ?
                                            results.data.map((el, index) => {
                                                const {id} = el;
                                                return (
                                                    <div
                                                        className="search_product_item"
                                                        onClick={() => this.handleActiveItem(index)}
                                                        key={id}
                                                    >
                                                        <div className="search_product_item_head">
                                                            <span>{el.id}</span>
                                                        </div>
                                                        <div className="search_product_item_info">
                                                            <div>
                                                                <span className="block_label">Товар</span>
                                                                <p className="block_value">{el.name}</p>
                                                            </div>
                                                            <div>
                                                                <span className="block_label">Артикул</span>
                                                                <p className="block_value">{el.article_number}</p>
                                                            </div>
                                                            <div>
                                                                <span className="block_label">Производитель</span>
                                                                <p className="block_value">{el.producer}</p>
                                                            </div>
                                                            <div>
                                                                <span className="block_label">Количество</span>
                                                                <p className="block_value">{el.amount}</p>
                                                            </div>
                                                            <div className="block_value_currency">
                                                                {el.price}
                                                                <div className="ru_value"> ₽</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : <li>Нет совпадений</li>
                                        }
                                    </div>
                                : null}
                            </div>

                            <div className="amount_requests">
                                <div>
                                    <span className="descriptions_amount">Количество</span>

                                    <div className="block_amount">
                                        <div className="amount">

                                            <button className="minus" disabled={activeItem.id ===0 || requestAmount <= 1}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.decreaseAmount()
                                                }}>
                                                <RemoveIcon/>
                                            </button>
                                            <span>{requestAmount}</span>
                                            <button className="plus" disabled={requestAmount === activeItem.amount}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    this.increaseAmount()
                                                }}>
                                                <IncreaseIcon/>
                                            </button>
                                        </div>
                                        <div className="info_amount"><img src={IconAlert} alt="IconAlert"/> Количество
                                            товара для оформления возврата
                                        </div>
                                    </div>
                                </div>
                                <div className="price_all">
                                    <span className="descriptions_amount">Сумма</span>
                                    <div className="price">{requestSum.toFixed(2)} ₽</div>
                                </div>
                            </div>

                            <div className="search_products_list selected_order">
                                <div className="search_product_item">
                                    <div className="search_product_item_head">
                                        <span>Информация о выбранном заказе</span>
                                    </div>
                                    <div className="search_product_item_info">
                                        <div>
                                            <span className="block_label">Товар</span>
                                            <p className="block_value">{activeItem.name}</p>
                                        </div>
                                        <div>
                                            <span className="block_label">Артикул</span>
                                            <p className="block_value">{activeItem.article_number}</p>
                                        </div>
                                        <div>
                                            <span className="block_label">Производитель</span>
                                            <p className="block_value">{activeItem.producer}</p>
                                        </div>
                                        <div className="block_value_currency">
                                            {activeItem.price}
                                            <div className="ru_value"> ₽</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="block_textarea_amount">
                                <span>Комментарий</span>
                                <textarea placeholder="Ваш комментарий" onChange={(e) => this.onChangeText(e)}
                                    value={this.comment}>
                                </textarea>
                            </div>

                            <div className="attach_file_block">
                                <input type="file" hidden={true} ref={(ref) => this.upload = ref}
                                    onChange={(e) => this.onChangeFile(e)}/>

                                <button className="attach_btn" onClick={(e) => this.onClickAttach(e)}>
                                    <img src={IconAttach} alt="IconAttach"/>
                                    Прикрепить файл
                                </button>
                                {files.map((file, index) => {
                                    return (
                                        <div className="document_info" key={index}>
                                            <img className="doc_icon" src={Iconfile} alt="Iconfile"/>
                                            <span>{file.name}</span>
                                            <button className="btn_delete_doc"
                                                onClick={(e) => this.removeFile(index)}>
                                                <img src={IconDeleteFile} alt="IconDeleteFile"/>
                                            </button>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                    <div className="btn-wrapper order-dialog-btn">
                        <DefaultButton
                            variant="outlined"
                            classes="cancel_btn"
                            onClick={this.toggleDialog}
                        >
                            Отмена
                        </DefaultButton>
                        <DefaultButton
                            loading={loadingBtn}
                            disabled={loadingBtn || this.disabledBtn}
                            onClick={() => {
                                this.createRefundRequestHandler();
                            }}
                        >
                            Создать заявку
                        </DefaultButton>
                    </div>
                    <IconButton
                        classes={{
                            root: "dialog_close_button"
                        }}
                        onClick={this.toggleDialog}
                    >
                        <img src={CloseDialogIcon} alt="close icon"/>
                    </IconButton>
                </DialogComponent>
                <SnackBar
                    open={snackBarAttr.isVisible}
                    classes={snackBarAttr.type}
                    vertical="top"
                    onClose={() => this.toggleSnack()}
                >
                    { snackBarAttr.message }
                </SnackBar>
            </TransitionedBlock>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getOrderByNumber,
        postCreateRequest,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(RefundRequestsHeaderButtonsPanel);
// export default RefundRequestsHeaderButtonsPanel;
