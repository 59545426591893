import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pagination from '../HelperComponents/Pagination/Pagination';
import PanelPage from '../HelperComponents/PanelPage/PanelPage';
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import SnackBar from "../HelperComponents/SnackBar/SnackBar";
import RefundRequestsHeaderButtonsPanel from "./RefundRequestsHeaderButtonsPanel/RefundRequestsHeaderButtonsPanel"
import Checkbox from "@material-ui/core/Checkbox";
import { getStatusRefundRequest, getStatusColor, splitByCommas } from "../../helpers/functions";
import { getRefundRequests, getRelatedRefundRequests, postCreateJoinRequest } from "../../actions/refundRequestsActions";
import { getProviders } from "../../actions/providersActions";
import './RefundRequests.scss';
import ControlPanel from '../HelperComponents/ControlPanel/ControlPanel';

class RefundRequests extends Component {

    state = {
        loading: true,
        activePage: 1,
        pageSize: 10,
        valueSearch: '',
        status: null,
        projects_list: [
            { label: "На рассмотрении", value: "processing" },
            { label: "Одобрена", value: "complete" },
            { label: "Отказ", value: "denied" },
            { label: "Ожидаем документы", value: "waiting_for_documents" },
            { label: "Ожидаем деталь", value: "waiting_for_details" },
        ],
        provider: null,
        provider_list: [],
        selectedRows: {},
        snackBarAttr: {
            isVisible: false,
            message: '',
        },
        actionLoading: false,
    };

    componentDidMount = () => {
        this.doRequest();
        this.getProviderList();
        const { location } = this.props;
        if (location.state && location.state.isVsibleSnackBar) {
            this.setState({
                snackBarAttr: {
                  isVisible: true,
                  message: location.state.snackBarMessage,
                }
            });
        }
        this.props.history.replace({
            ...this.props.location,
            state: {},
        })
    };
    
    get selectedRowsLength() {
        return Object.values(this.state.selectedRows).filter((row) => row.checked).length
    }

    doRequest = () => {
        const { getRefundRequests } = this.props;
        const { activePage, pageSize, valueSearch, status, provider } = this.state;
        // добавить сюда обнуление selectedRows в телеге в избранном

        let arr = [];
        if (!!status && status !== null) {
            arr.push(`status=${status.value}`);
            this.setState({ status })
        } else {
            this.setState({ status: '' })
        }
        if (valueSearch !== "") {
            arr.push(`query=${valueSearch}`);
        }
        if (provider !== null) {
            arr.push(`provider=${provider.value}`);
        }
        arr.push(`page_size=${pageSize}`);
        arr.push(`page=${activePage}`);

        getRefundRequests(arr).then(res => {
            if (res.payload && res.payload.status) {
                const refundRequestMap = res.payload.data.results.reduce((acc, item) => {
                    acc[item.id] = {
                        checked: false,
                        disabled: false,
                        amount: item.amount,
                        id: item.id,
                        orderNum: item.ordernum
                    }
                    return acc;
                }, {})
                this.setState({ loading: false, selectedRows: refundRequestMap });
            } else {
                this.setState({ loading: false, activePage: 1 });
                this.doRequest()
            }
        });
    };

    toggleSnack = () => {
        this.setState({
            snackBarAttr: {
              isVisible: false,
              message: '',
            }
        });
      };

    changeValueSearch = e => {
        this.setState({ valueSearch: e.target.value });
    };

    handleSearch = e => {
        const { status } = this.state;

        if (e.keyCode === 13) {
            this.setState({ activePage: 1 });
            setTimeout(() => {
                this.doRequest(status);
            }, 0);
        } else if (e === "iconClick") {
            setTimeout(() => {
                this.doRequest(status);
            }, 0);
        }
    };

    handleChange = (name, event) => {
        this.setState({ [name]: event });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };    

    changePage = (pageNumber) => {
        this.setState({ activePage: pageNumber.selected + 1 });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    };

    changePageSize = (pageSize) => {
        this.setState({ pageSize: pageSize });
        setTimeout(() => {
            this.doRequest();
        }, 0);
    }

    getProviderList() {
        const { getProviders } = this.props;
        
        getProviders().then((res) => {
            if (res.payload && res.payload.status) {
                const providers = [];
                res.payload.data.forEach((item) => {
                    providers.push({label: item.name, value: item.name})
                })
                this.setState({ provider_list: providers });
            } else {
                this.setState({ provider_list: [] });
            }
        })
    }

    async handleChangeCheckbox(request) {
        const { getRelatedRefundRequests } = this.props;

        const currentSelectedRows = { ...this.state.selectedRows };
        const isChecking = !currentSelectedRows[request.id].checked;

        currentSelectedRows[request.id] = {
            ...currentSelectedRows[request.id],
            checked: isChecking,
            disabled: false,
        };

        const checkedRows = Object.values(currentSelectedRows)
            .filter((row) => row.checked)
            .map((row) => row.id);

        let relatedRequests = [];
        if (checkedRows.length > 0) {
            const relatedRequestsResponse = await getRelatedRefundRequests(checkedRows);
            relatedRequests = relatedRequestsResponse.payload.data;
        }

        Object.keys(currentSelectedRows).forEach((key) => {
            if (key !== request.id.toString()) {
                const isRelated = relatedRequests.some(
                    (relatedRequest) => relatedRequest.id.toString() === key
                );
                currentSelectedRows[key].disabled = checkedRows.length > 0 && !isRelated && !currentSelectedRows[key].checked
            }
        });

        this.setState({ selectedRows: currentSelectedRows });
    }

    createRefundRequestHandler = (isCreatingRefundRequestsuccessfully) => {
        if (isCreatingRefundRequestsuccessfully) {
            this.doRequest();
        }
    }

    createJoinRequestHandler = async () => {
        this.setState({ actionLoading: true });
        const checkedRows = Object.values(this.state.selectedRows).filter((row) => row.checked);
        try {
            const response = await this.props.postCreateJoinRequest(checkedRows.map((row) => row.id));
            if (response.type === 'POST_CREATE_JOIN_REQUEST_FAIL') {
                throw new Error('Ошибка при объединении заявок');
            }
            this.doRequest();
            this.setState({ snackBarAttr: {
                isVisible: true,
                message: 'Заявки успешно объединены',
                type: 'success',
            } });
        } catch {
            this.setState({ snackBarAttr: {
                isVisible: true,
                message: 'Ошибка при объединении заявок',
            } });
        }
        this.setState({ actionLoading: false });
    }

    checkChildTagHandler = (event) => {
        if (event.target.closest('span')) {
            event.preventDefault();
        }
    }

    render() {
        const { refund_requests: { all_refund_requests }} = this.props;
        const { loading, activePage, pageSize, valueSearch, status, projects_list, provider, provider_list, selectedRows, snackBarAttr } = this.state;

        if (loading) return null;
        return (
            <TransitionedBlock>
                <div className="refund_requests_page container">
                    <div className="flex-center-btw title-block-page">
                        <div className="title-page">
                            <h1>Заявки на возврат</h1>
                        </div>
                        <RefundRequestsHeaderButtonsPanel
                            selectedRowsLength={this.selectedRowsLength}
                            selectedRows={selectedRows}
                            createJoinRequest={this.createJoinRequestHandler}
                            createRefundRequest={this.createRefundRequestHandler}
                            actionLoading={this.state.actionLoading}
                            refetch={this.doRequest}
                        />
                    </div>
                    <div className="page_block">
                        <PanelPage
                            doRequest={this.doRequest}
                            changeValueSearch={this.changeValueSearch}
                            handleSearch={this.handleSearch}
                            handleChange={this.handleChange}
                            valueSeacrh={valueSearch}
                            status={status}
                            projects_list={projects_list}
                            provider={provider}
                            provider_list={provider_list}
                        />
                        <div className="refund_requests_table_wrapper">
                            <div className="transactions_table">
                                <div className="table_container transactions_columns">
                                    <div className="table_header">
                                        <div className="table_row">
                                            <div className="row_item">Наименование</div>
                                            <div className="row_item">Артикул</div>
                                            <div className="row_item">Заказ</div>
                                            <div className="row_item">Компания</div>
                                            <div className="row_item">Поставщик</div>
                                            <div className="row_item">Дата/время</div>
                                            <div className="row_item">Статус</div>
                                            <div className="row_item" style={{ textAlign: 'center' }}>К-во товара</div>
                                            <div className="row_item" style={{ textAlign: 'center', display: 'block' }}>Сумма</div>
                                            <div className="row_item" />
                                        </div>
                                    </div>
                                    <div className="table_body">
                                        {all_refund_requests.results.length !== 0 ? all_refund_requests.results.map((request, idRequest) => (
                                            <Link 
                                                to={`/admin/refund-request/${request.id}`} 
                                                className="table_row" key={idRequest}
                                                onClick={(e) => this.checkChildTagHandler(e)}
                                            >
                                                <div className="row_item">{request.product_info && request.product_info.name}</div>
                                                <div className="row_item">{request.product_info && request.product_info.article_number}</div>
                                                <div className="row_item">{request.ordernum}</div>
                                                <div className="row_item">{request.company}</div>
                                                <div className="row_item">{request.provider}</div>
                                                <div className="row_item">{moment(request.date).format('DD.MM.YYYY HH:mm')}</div>
                                                <div className="row_item">
                                                    {request.status ?
                                                        <div className="block_status_info">
                                                            <span className="status" style={{background: `${getStatusColor(request.status)}`}} />
                                                            <span className="text">{getStatusRefundRequest(request.status)}</span>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                <div className="row_item" style={{ textAlign: 'center' }}>{request.amount}</div>
                                                <div className="row_item" style={{ textAlign: 'center' }}>
                                                    {splitByCommas(request.price)} ₽
                                                </div>
                                                { selectedRows[request.id] &&
                                                    <Checkbox 
                                                        className={`${selectedRows[request.id].disabled ? "row_item_disabled" : "row_item"}`}
                                                        style={{ textAlign: 'center' }}
                                                        checked={selectedRows[request.id].checked}
                                                        disabled={selectedRows[request.id].disabled}
                                                        onClick={(e) => e.stopPropagation()}
                                                        onChange={() => this.handleChangeCheckbox(request)}
                                                    />
                                                }
                                            </Link>
                                        )) :
                                            <h1 style={{marginTop: '30px'}}>Заявок не найдено</h1>
                                        }
                                    </div>
                                </div>
                                <div className='control_panel'>
                                    {all_refund_requests.count && all_refund_requests.count > pageSize ?
                                        <div>
                                            <Pagination
                                                active={activePage}
                                                pageItemsCount={pageSize}
                                                pageTotalCount={all_refund_requests.count}
                                                onChange={this.changePage}
                                            />
                                        </div>
                                        : null}
                                    <ControlPanel onChange={(event) => this.changePageSize(event.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SnackBar
                    open={snackBarAttr.isVisible}
                    classes={snackBarAttr.type === 'success' ? 'success' : 'error'}
                    vertical="top"
                    onClose={() => this.toggleSnack()}
                >
                    {snackBarAttr.message}
                </SnackBar>
            </TransitionedBlock>
        );
    }
}

const mapStateToProps = ({refund_requests}) => ({
    refund_requests
});

const mapDispatchToProps = {
    getRefundRequests,
    getProviders,
    getRelatedRefundRequests,
    postCreateJoinRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RefundRequests);