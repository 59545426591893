import React, { useState, useEffect } from 'react';
import CloseDialogIcon from '../../../../assets/image/Mask.png';
import DefaultButton from "../../../Buttons/DefaultButton/DefaultButton";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { splitRefundRequest } from "../../../../actions/refundRequestsActions";

const SplitRefundDialogContent = ({ onClose, onSuccess, selectedRow={}, splitRefundRequest, onError }) => {
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [firstAmount, setFirstAmount] = useState(selectedRow.amount);
    const [secondAmount, setSecondAmount] = useState(0);

    useEffect(() => {
        setDisabledBtn(firstAmount <= 0 || secondAmount <= 0);
    }, [firstAmount, secondAmount]);

    const handleIncreaseFirst = () => {
        if (secondAmount > 0) {
            setFirstAmount(prev => prev + 1);
            setSecondAmount(prev => prev - 1);
        }
    };

    const handleDecreaseFirst = () => {
        if (firstAmount > 0) {
            setFirstAmount(prev => prev - 1);
            setSecondAmount(prev => prev + 1);
        }
    };

    const handleIncreaseSecond = () => {
        if (firstAmount > 0) {
            setFirstAmount(prev => prev - 1);
            setSecondAmount(prev => prev + 1);
        }
    };

    const handleDecreaseSecond = () => {
        if (secondAmount > 0) {
            setFirstAmount(prev => prev + 1);
            setSecondAmount(prev => prev - 1);
        }
    };

    const handleSplit = async () => {
        setLoadingBtn(true);
        try {
            await splitRefundRequest(selectedRow.id, [{amount: firstAmount}, {amount: secondAmount}]);
            setLoadingBtn(false);
            onSuccess();
        } catch (error) {
            onError(error);
            setLoadingBtn(false);
        }
    };


    return (
        <div>
            <div className="title-dialog">
                Разделить заявку №{selectedRow.orderNum}
            </div>
            <div className="dialog-form-wrapper">
                <div className="requests_split">
                    <div className="requests_split_item">
                        <h6>Первый заказ</h6>
                        <IconButton onClick={handleDecreaseFirst} disabled={firstAmount <= 0}>
                            <RemoveIcon />
                        </IconButton>
                        <span>{firstAmount}</span>
                        <IconButton onClick={handleIncreaseFirst} disabled={secondAmount <= 0}>
                            <AddIcon />
                        </IconButton>
                    </div>
                    <div className="requests_split_item">
                        <h6>Второй заказ</h6>
                        <IconButton onClick={handleDecreaseSecond} disabled={secondAmount <= 0}>
                            <RemoveIcon />
                        </IconButton>
                        <span>{secondAmount}</span>
                        <IconButton onClick={handleIncreaseSecond} disabled={firstAmount <= 0}>
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>
                <div className="requests_split_total">
                    <span>Всего: {selectedRow.amount}</span>
                </div>
            </div>
            <div className="btn-wrapper order-dialog-btn">
                <DefaultButton
                    variant="outlined"
                    classes="cancel_btn"
                    onClick={onClose}
                >
                    Отмена
                </DefaultButton>
                <DefaultButton
                    loading={loadingBtn}
                    disabled={loadingBtn || disabledBtn}
                    onClick={handleSplit}
                >
                    Разделить
                </DefaultButton>
            </div>
            <IconButton
                classes={{
                    root: "dialog_close_button"
                }}
                onClick={onClose}
            >
                <img src={CloseDialogIcon} alt="close icon"/>
            </IconButton>
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        splitRefundRequest,
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(SplitRefundDialogContent);
